<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar" help-page-component="WprsOverview"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 pl-5 pr-4>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <WprsListing v-if="operationsView == false"></WprsListing>
                    <WprsOperationsListing v-if="operationsView"></WprsOperationsListing>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    // import ExportTable from "Components/Appic/ExportTable";
    // import WprsListing from "Components/Appic/WprsListing";
    import LoadingComponent from "Components/Appic/LoadingComponent";
    import {mapFields} from "vuex-map-fields";

    const WprsListing = () => ({
        component: import("Components/Appic/WprsListing"),
        loading: LoadingComponent
    })

    const WprsOperationsListing = () => ({
        component: import("Components/Appic/WprsOperationsListing"),
        loading: LoadingComponent
    })

    export default {
        name: "WprsOverview",
        components: { WprsListing, WprsOperationsListing },
        title: '',
        data() {
            return {
                loader: false
            }
        },
        computed: {
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
            operationsView() {
                if(this.$route.name == 'wpr_operations_listing'){
                    return true
                }
                return false
            },
        },
        methods: {
            newWpr(){
                let tab = window.open('/v1/wprs/add','_blank')
            }
        },
        mounted(){
            this.$title = this.$t('message.titles.wprs')
        }
    }
</script>

<style>
    .v-data-table td {
        padding: 0 5px;
    }
    .v-data-table td {
        padding: 0 5px;
        font-size: 0.8rem !important;
    }
    .font-xs {
        font-size: 0.65rem;
    }
    .mt-5px {
        margin-top: 5px !important;
    }
    .mt-6px {
        margin-top: 6px !important;
    }
</style>
